<template>
  <styleguide-layout>
    <sm-anchor-point id="sm-avatar" />
    <sm-card header-title="Avatar">
      <styleguide-element
        title="Avatar badge"
        example="&lt;sm-avatar :user='{avatar: '#', name: 'Lorem'}' /&gt;"
        required
      >
        <sm-avatar :user="user" />
      </styleguide-element>

      <sm-card header-title="The user object">
        <div class="grid grid-cols-2 gap-4">
          <styleguide-element
            title="The name of the user"
            prop="name"
            type="String"
            example="name: '...'"
            required
          />
          <styleguide-element
            title="The avatar image source of the user"
            prop="avatar"
            type="String"
            example="avatar: '...'"
            required
          />
          <styleguide-element
            title="The URL of the user"
            prop="profile_url"
            type="String"
            example="profile_url: '...'"
            required
          />
        </div>
      </sm-card>

      <styleguide-element
        title="The link type of the badge"
        description="If the type is link, it generates an <a> tag, if button is generates a <button> (if href is available) or <span> tag"
        type="String"
        available-values="null, 'link'"
        example="type='link'"
      >
        <sm-avatar
          :user="user"
          type="link" />
      </styleguide-element>

      <styleguide-element
        title="Generates an inertia link with <button> tag (overridden by the profile_url user option) "
        type="String"
        example="href='#'"
      >
        <sm-avatar
          :user="user"
          href="#" />
      </styleguide-element>
    </sm-card>

    <sm-anchor-point id="sm-boolean-flag" />
    <sm-card header-title="Boolean flag">
      <styleguide-element
        title="Generates a true of false check or cross icon "
        example="&lt;sm-boolean-flag :value='true' /&gt;"
        prop="value"
        type="Boolean"
        default-value="null"
      >
        <sm-boolean-flag :value="true" />
        <sm-boolean-flag :value="false" />
      </styleguide-element>
    </sm-card>

    <sm-anchor-point id="sm-anchor-point" />
    <sm-card header-title="Anchor point">
      <styleguide-element
        title="Generates an anchor to any point of the dom "
        example="&lt;sm-anchor-point id='...' /&gt;"
        prop="id"
        type="String"
      >
        <sm-anchor-point id="sm-demo" />
      </styleguide-element>
    </sm-card>
  </styleguide-layout>
</template>

<script setup>
import StyleguideLayout from "@/inertia/components/styleguide/StyleguideLayout.vue";

const user = ref({
  avatar: "https://placehold.co/400x400",
  name: "Lorem ipsum"
});

</script>
