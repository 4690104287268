<template>
  <sm-card header-title="Emits">
    <div class="sm-row">
      <div class="w-1/2 sm-col">
        <p class="mb-2">
          Emits the <code>update:modelValue</code> event when the value of the picker has changed (in vue2 its the input event)<br>
          <strong class="inline-block pl-2 w-36">Emit name:</strong> @update:modelValue<br>
          <code class="inline-block pl-2"><pre class="text-xs text-bo-yellow-600">&lt;sm-date-picker @update:modelValue="customEvent" /&gt;</pre></code>
        </p>
      </div>
      <div class="w-1/2 sm-col">
        <sm-date-picker
          label="Emit the update:modelValue event (check the console)"
          @update:modelValue="console.log('Emitted the update:modelValue event')" />
        <sm-date-range-picker
          label="Emit the update:modelValue event (check the console)"
          @update:modelValue="console.log('Emitted the update:modelValue event')" />
        <sm-time-picker
          label="Emit the update:modelValue event (check the console)"
          @update:modelValue="console.log('Emitted the update:modelValue event')" />
      </div>
    </div>
    <hr>

    <div class="sm-row">
      <div class="w-1/2 sm-col">
        <p class="mb-2">
          Emits the onEnter event when the Enter key has pressed<br>
          <strong class="inline-block pl-2 w-36">Emit name:</strong> @onEnter<br>
          <code class="inline-block pl-2"><pre class="text-xs text-bo-yellow-600">&lt;sm-date-picker @onEnter="customEvent" /&gt;</pre></code>
        </p>
      </div>
      <div class="w-1/2 sm-col">
        <sm-date-picker
          focus-today
          label="Emit the onEnter event (check the console)"
          @onEnter="console.log('Emitted the onEnter event')" />
      </div>
    </div>
    <hr>

    <div class="sm-row">
      <div class="w-1/2 sm-col">
        <p class="mb-2">
          Emits the clear event when the data has cleared<br>
          <strong class="inline-block pl-2 w-36">Emit name:</strong> @clear<br>
          <code class="inline-block pl-2"><pre class="text-xs text-bo-yellow-600">&lt;sm-date-picker @clear="customEvent" /&gt;</pre></code>
        </p>
      </div>
      <div class="w-1/2 sm-col">
        <sm-date-range-picker
          clearable
          :model-value="dateRangePickerValue"
          label="Emit the clear event (check the console)"
          @clear="console.log('Emitted the clear event')" />
      </div>
    </div>
    <hr>
  </sm-card>
</template>

<script setup>
const dateRangePickerValue = ref({ start: "2024-07-10", end: "2024-07-20" });
</script>
