<template>
  <styleguide-layout>
    <sm-card header-title="Default usage">
      <sm-button
        title="Click me"
        @click="toggleModalVisibility(0)" />
      <sm-modal
        :modal-is-visible="modal[0]"
        @toggleModalVisibility="toggleModalVisibility(0)" />
      <br>
      <code class="!-mt-4"><pre>&lt;sm-modal :modal-is-visible="modal" @toggleModalVisibility="toggleModalVisibility" /&gt;</pre>
        <pre class="mb-4 ">&lt;sm-button title="Click me" @click="toggleModalVisibility" /&gt;</pre>
        <pre class="text-bo-green">const modal = ref(false);
const toggleModalVisibility = () => {
  modal.value = !modal.value;
};</pre></code>
    </sm-card>

    <sm-card header-title="Props">
      <div class="">
        <styleguide-element
          title="Set the visibility of the modal"
          prop="modalIsVisible"
          type="Boolean"
          default-value="false"
          :example="`:modal-is-visible='${modal[1]}'`"
          required
        >
          <sm-button
            title="Click me"
            @click="toggleModalVisibility(1)" />
          <sm-modal
            :modal-is-visible="modal[1]"
            @toggleModalVisibility="toggleModalVisibility(1)" />
        </styleguide-element>

        <styleguide-element
          title="Add title to the modal"
          prop="modalTitle"
          type="String"
          default-value="SportMate Club"
          example="modal-title='...'"
        >
          <sm-button
            title="Click me"
            @click="toggleModalVisibility(2)" />
          <sm-modal
            :modal-is-visible="modal[2]"
            modal-title="This is a title"
            @toggleModalVisibility="toggleModalVisibility(2)" />
        </styleguide-element>

        <styleguide-element
          title="Set the size of the modal"
          prop="size"
          type="String"
          default-value="''"
          available-values="-, lg, xl"
          example="size='...'"
        >
          <sm-button
            title="Click me (default modal)"
            @click="toggleModalVisibility(3)" />
          <sm-modal
            :modal-is-visible="modal[3]"
            modal-title="This is a title"
            @toggleModalVisibility="toggleModalVisibility(3)" />
          <br>
          <sm-button
            title="Click me (large modal)"
            @click="toggleModalVisibility(4)" />
          <sm-modal
            :modal-is-visible="modal[4]"
            size="lg"
            @toggleModalVisibility="toggleModalVisibility(4)" />
          <br>
          <sm-button
            title="Click me (extra large modal)"
            @click="toggleModalVisibility(5)" />
          <sm-modal
            :modal-is-visible="modal[5]"
            size="xl"
            @toggleModalVisibility="toggleModalVisibility(5)" />
        </styleguide-element>

        <styleguide-element
          title="Disallow to close the modal"
          prop="closeable"
          type="Boolean"
          example=":closeable='true'"
        >
          <sm-button
            title="Click me"
            @click="toggleModalVisibility(6)" />
          <sm-modal
            :modal-is-visible="modal[6]"
            :closeable="false"
            @toggleModalVisibility="toggleModalVisibility(6)" />
        </styleguide-element>

        <styleguide-element
          title="Add close button to the modal"
          prop="closeButton"
          type="Boolean"
          example=":close-button='true'"
        >
          <sm-button
            title="Click me"
            @click="toggleModalVisibility(7)" />
          <sm-modal
            :modal-is-visible="modal[7]"
            close-button
            @toggleModalVisibility="toggleModalVisibility(7)" />
        </styleguide-element>

        <styleguide-element
          title="Add close button to the modal"
          prop="bodyClasses"
          type="String"
          example="body-classes='...'"
        >
          <sm-button
            title="Click me"
            @click="toggleModalVisibility(8)" />
          <sm-modal
            :modal-is-visible="modal[8]"
            body-classes="bg-bo-blue"
            @toggleModalVisibility="toggleModalVisibility(8)" />
        </styleguide-element>
      </div>
    </sm-card>

    <sm-card header-title="Slots">
      <div class="">
        <styleguide-element
          title="Add slot to the body (default slot)"
          slot-name="#default"
          slot-example="&lt;sm-modal&gt;Body&lt;/sm-card&gt;"
        >
          <sm-button
            title="Click me"
            @click="toggleModalVisibility(9)" />
          <sm-modal
            :modal-is-visible="modal[9]"
            @toggleModalVisibility="toggleModalVisibility(9)">
            Body
          </sm-modal>
        </styleguide-element>

        <styleguide-element
          title="Add slot to the footer"
          slot-name="#footer"
          slot-example="&lt;template #footer&gt;Footer slot&lt;/template&gt;"
        >
          <sm-button
            title="Click me"
            @click="toggleModalVisibility(10)" />
          <sm-modal
            :modal-is-visible="modal[10]"
            @toggleModalVisibility="toggleModalVisibility(10)">
            <template #footer>
              Footer slot
            </template>
          </sm-modal>
        </styleguide-element>
      </div>
    </sm-card>

    <sm-card header-title="Emits">
      <div class="">
        <styleguide-element
          title="Emits the open/close event"
          emit-name="toggleModalVisibility"
          emit-example="@toggleModalVisibility='customEvent'"
          required
        >
          <sm-button
            title="Click me"
            @click="toggleModalVisibility(11)" />
          <sm-modal
            :modal-is-visible="modal[11]"
            @toggleModalVisibility="toggleModalVisibility(11)" />
        </styleguide-element>
      </div>
    </sm-card>
  </styleguide-layout>
</template>

<script setup>
import StyleguideLayout from "../../../components/styleguide/StyleguideLayout.vue";

const modal = ref([false, false, false, false, false, false, false, false, false, false, false, false, false]);

const toggleModalVisibility = (i) => {
  modal.value[i] = !modal.value[i];
};
</script>

<style lang="postcss" scoped>
  code {
    @apply inline-block;

    pre {
      @apply text-xs leading-5;
    }
  }
</style>
