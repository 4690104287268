<template>
  <styleguide-layout>
    <sm-card header-title="Default usage">
      <div class="sm-row">
        <div class="w-1/2 sm-col">
          <p class="mb-2">
            Create a toggleable button with options <br><br>
            <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">&lt;sm-toggle-button title="Toggle button" :options="toggleButtonOptions" /&gt;</pre></code>
          </p>
        </div>
        <div class="w-1/2 sm-col">
          <div class="text-xs">
            <sm-toggle-button
              title="Toggle button"
              :options="toggleButtonOptions" />
          </div>
        </div>
      </div>
    </sm-card>

    <sm-card header-title="Required props">
      <styleguide-element
        title="Set the button title"
        prop="title"
        type="String"
        example="title='...'"
        required
      >
        <sm-toggle-button
          title="Toggle button title"
          :options="toggleButtonOptions" />
      </styleguide-element>

      <styleguide-element
        title="Set the options"
        prop="options"
        type="Array of Objects"
        example=":options='[{id: 1, label: 'Label', to: '/', icon: 'plus-circle'}]'"
        required
      >
        <sm-toggle-button
          title="Toggle button"
          :options="toggleButtonOptions" />
      </styleguide-element>
    </sm-card>

    <sm-card header-title="The options array prop">
      <div class="grid grid-cols-2 gap-4">
        <styleguide-element
          title="Add ID to the option"
          prop="id"
          type="String, Number"
          example="id: 1"
          required
        />

        <styleguide-element
          title="Add label to the option"
          prop="label OR name"
          type="String"
          example="label: 'This is the label of the option'"
          required
        />

        <styleguide-element
          title="Add link to the option"
          prop="to"
          type="String"
          example="to: '/'"
          required
        />

        <styleguide-element
          title="Set the route of the option to legacy link (outside inertia)"
          prop="toLegacyRoute"
          type="String"
          example="toLegacyRoute: '/manage/appointments'"
          required
        />

        <styleguide-element
          title="Add clickable action to the option"
          prop="action"
          type="Function"
          example="action: () => console.log('Action')"
          required
        />

        <styleguide-element
          title="Add icon to the option"
          prop="icon"
          type="String"
          example="icon: 'star'"
        />

        <styleguide-element
          title="Add class to the option item"
          prop="class"
          type="String"
          example="class: 'bg-bo-red'" />

        <styleguide-element
          title="Set the visibility to the option item"
          prop="visible"
          type="Boolean OR Function"
          example="visible: false" />
      </div>
    </sm-card>

    <sm-card header-title="The actionData prop">
      <sm-alert
        color="info"
        class="flex">
        Add extra level of actions from backend, main use: <Link
          href="/manage/customers/221/tickets"
          class="ml-1 font-bold">
          Customer tickets page
        </Link>
      </sm-alert>
      <styleguide-element
        title="Set the visibility to the option item"
        prop="align"
        type="String"
      />
    </sm-card>

    <sm-card header-title="Props">
      <styleguide-element
        title="Disable the button"
        prop="disabled"
        type="Boolean"
        default-value="false"
        example=":disabled='true'"
      >
        <sm-toggle-button
          title="Toggle button"
          disabled
          :options="toggleButtonOptions" />
      </styleguide-element>

      <styleguide-element
        title="Set the size of the button"
        prop="size"
        type="String"
        available-values="xs, base, lg... (all the button sizes)"
        example="size='...'"
      >
        <sm-toggle-button
          title="Toggle button (xs)"
          size="xs"
          class="mb-2"
          :options="toggleButtonOptions" />
      </styleguide-element>

      <styleguide-element
        title="Set the color of the button"
        prop="color"
        type="String"
        available-values="primary, primary-outline, success... (all the button colors)"
        example="size='...'"
      >
        <sm-toggle-button
          title="Toggle button (xs)"
          color="primary"
          class="mb-2"
          :options="toggleButtonOptions" />
      </styleguide-element>
    </sm-card>
  </styleguide-layout>
</template>

<script setup>
import StyleguideLayout from "@/inertia/components/styleguide/StyleguideLayout.vue";
import { Link } from "@inertiajs/vue3";

const toggleButtonOptions = ref([
  {
    id: 1,
    label: "First element (Inertia link)",
    to: "/manage/customers",
    icon: "plus-circle"
  },{
    id: 2,
    label: "Second element (Legacy route)",
    type: "link",
    toLegacyRoute: "/manage/appointments",
    icon: "plus-circle"
  },{
    id: 3,
    label: "Third element (Action)",
    type: "link",
    action: () => alert("Action"),
    icon: "plus-circle"
  }
]);
</script>
