<template>
  <div :class="{'sm-table-responsive': responsive}">
    <table
      class="sm-table"
      :class="tableClasses">
      <thead>
        <tr>
          <th
            v-for="column in tableColumns"
            :key="column.id">
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(data, rowIndex) in tableData"
          :key="rowIndex">
          <td
            v-for="(value, colIndex) in Object.values(data)"
            :key="colIndex"
            :class="columnClasses ? columnClasses[Object.keys(data)[colIndex]] : null">
            <slot
              v-if="$slots[Object.keys(data)[colIndex]]"
              :name="Object.keys(data)[colIndex]"
              :data="data" />
            <template v-else>
              {{ value }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
const props = defineProps({
  tableData: { type: Array, required: true, default: () => [] },
  tableColumns: { type: Array, required: true, default: () => [] },
  size: { type: String, required: false, default: "" }, // sm
  color: { type: String, required: false, default: "" }, // primary, info, warning, success
  bordered: { type: Boolean, required: false, default: false },
  noHeading: { type: Boolean, required: false, default: false }, //no-heading
  responsive: { type: Boolean, required: false, default: false },
  hover: { type: Boolean, required: false, default: false },
  columnClasses: { type: Object, required: false, default: null },
});

const tableClasses = computed(() => {
  const size = props.size === "sm" ? "sm-table-sm" : null;
  const color = props.color ? `sm-table-${props.color}` : null;
  const border = props.bordered ? "sm-table-bordered" : null;
  const noHeading = props.noHeading ? "sm-table-no-heading" : null;
  const hover = props.hover ? "sm-table-hover" : null;
  return `${border ? border : ""} ${color ? color : ""} ${noHeading ? noHeading : ""} ${size ? size : ""} ${hover ? hover : ""}`;
});
</script>
