<template>
  <styleguide-layout>
    <sm-card header-title="Default usage">
      <div class="sm-row">
        <div class="w-1/2 sm-col">
          <p class="mb-2">
            Create alert dialog <br><br>
            <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">&lt;sm-alert-dialog name="alertDialog" :visible="alertisVisible" @onAccept="toggleAlert" /&gt;</pre></code>
          </p>
        </div>
        <div class="w-1/2 sm-col">
          <div class="text-xs">
            <sm-button
              title="Toggle alert"
              @click="toggleAlert(0)" />
            <sm-alert-dialog
              name="alertDialog"
              :visible="alertisVisible[0]"
              @onAccept="toggleAlert(0)" />
          </div>
        </div>
      </div>
    </sm-card>

    <sm-card header-title="Props">
      <styleguide-element
        title="Add nema to the alert dialog"
        prop="name"
        type="String"
        example="name='...'"
        required
      >
        <sm-button
          title="Toggle alert"
          @click="toggleAlert(1)" />
        <sm-alert-dialog
          name="alertDialog"
          :visible="alertisVisible[1]"
          @onAccept="toggleAlert(1)" />
      </styleguide-element>

      <styleguide-element
        title="Set the visiblity of the alert dialog"
        prop="visible"
        type="Boolean"
        default-value="false"
        example=":visible='true'"
        required
      >
        <sm-button
          title="Toggle alert"
          @click="toggleAlert(2)" />
        <sm-alert-dialog
          name="alertDialog"
          :visible="alertisVisible[2]"
          @onAccept="toggleAlert(2)" />
      </styleguide-element>

      <styleguide-element
        title="Set the message of the alert dialog"
        prop="message"
        type="String"
        example="message='...'"
      >
        <sm-button
          title="Toggle alert"
          @click="toggleAlert(3)" />
        <sm-alert-dialog
          name="alertDialog"
          message="Lorem ipsum dolor set amet"
          :visible="alertisVisible[3]"
          @onAccept="toggleAlert(3)" />
      </styleguide-element>
    </sm-card>

    <sm-card header-title="Emits">
      <styleguide-element
        title="Emits the onAccept event after the OK button clicked"
        emit-name="onAccept"
        emit-example="@onAccept='onAccept'"
        required
      >
        <sm-button
          title="Toggle alert"
          @click="toggleAlert(4)" />
        <sm-alert-dialog
          name="alertDialog"
          :visible="alertisVisible[4]"
          @onAccept="() => console.log('OK pressed')" />
      </styleguide-element>
    </sm-card>
  </styleguide-layout>
</template>

<script setup>
import StyleguideLayout from "@/inertia/components/styleguide/StyleguideLayout.vue";

const alertisVisible = ref([false, false, false, false, false, false, false, false, false, false, false, false, false]);

const toggleAlert = (i) => {
  alertisVisible.value[i] = !alertisVisible.value[i];
};
</script>
