<template>
  <styleguide-layout>
    <sm-card header-title="Form button">
      <sm-alert color="info">
        A simple button which executes a form submit action with some minimal prop
      </sm-alert>

      <styleguide-element
        title="Form button"
        example="&lt;sm-form-button route='/' name='formButtonRoute' method='post' icon='save' /&gt;"
        required
      >
        <sm-form-button
          route="/"
          name="formButtonRoute"
          method="post"
          icon="save"
        />
      </styleguide-element>

      <hr>

      <styleguide-element
        title="The route of the form"
        prop="route"
        type="String"
        required
        example="route='...'"
      >
        <sm-form-button
          route="/"
          name="formButtonRoute"
          method="post"
          icon="save"
        />
      </styleguide-element>

      <styleguide-element
        title="The name of the form"
        prop="name"
        type="String"
        required
        example="name='...'"
      >
        <sm-form-button
          route="/"
          name="formButtonRoute"
          method="post"
          icon="save"
        />
      </styleguide-element>

      <styleguide-element
        title="The method of the form"
        prop="method"
        type="String"
        default-value="post"
        available-values="post, put, patch, ..."
        example="method='...'"
        required
      >
        <sm-form-button
          route="/"
          name="formButtonRoute"
          method="post"
          icon="save"
        />
      </styleguide-element>

      <styleguide-element
        title="The icon of the button (same prop as icon prop of the sm-button)"
        prop="icon"
        type="String"
        example="icon='...'"
        required
      >
        <sm-form-button
          route="/"
          name="formButtonRoute"
          method="post"
          icon="check"
        />
      </styleguide-element>

      <styleguide-element
        title="The postable data object of the form"
        prop="data"
        type="String"
        example="data='...'"
      >
        <sm-form-button
          route="/"
          name="formButtonRoute"
          method="post"
          icon="save"
        />
      </styleguide-element>

      <hr>

      <styleguide-element
        title="The postable data object of the form"
        emit="onSuccess"
        emit-example="@onSuccess='...'"
      >
        <sm-form-button
          route="/"
          name="formButtonRoute"
          method="post"
          icon="save"
        />
      </styleguide-element>
    </sm-card>

    <sm-card header-title="Delete button">
      <sm-alert color="info">
        A delete button which opens a confirmation modal
      </sm-alert>

      <styleguide-element
        title="Delete button"
        example="&lt;sm-delete-button name='deleteButtonName' @onDelete='onDeleteAction' /&gt;"
        required
      >
        <sm-delete-button
          name="deleteButton"
          @onDelete="onDelete"
        />
      </styleguide-element>

      <hr>

      <styleguide-element
        title="The name of the confirm group"
        prop="name"
        type="String"
        example="name='...'"
        default-value="delete"
        required
      >
        <sm-delete-button
          name="deleteButton"
          @onDelete="onDelete"
        />
      </styleguide-element>

      <styleguide-element
        title="The route of the form (if not provieded, the onDelete event is emitted)"
        prop="route"
        type="String"
        example="route='...'"
      >
        <sm-delete-button
          name="deleteButton"
          route="/"
          @onDelete="onDelete"
        />
      </styleguide-element>

      <styleguide-element
        title="The preset of the delete button"
        prop="preset"
        type="String"
        example="preset='...'"
        default-value="delete-icon"
      >
        <sm-delete-button
          name="deleteButton"
          route="/"
          preset="delete"
          @onDelete="onDelete"
        />
      </styleguide-element>

      <styleguide-element
        title="The translation object of the confirm modal"
        prop="translations"
        type="Object"
        example="translations='{ header: 'Please confirm', message: 'Are you sure?' }'"
        :default-value="{ header: $t('generic.delete_modal_title'), message: $t('generic.are_you_sure') }"
      >
        <sm-delete-button
          name="deleteButton"
          route="/"
          preset="delete"
          :translations="{
            header: $t('generic.delete_modal_title'),
            message: $t('Biztosan el akarod távolítani ezt a terméket?')
          }"
          @onDelete="onDelete"
        />
      </styleguide-element>
    </sm-card>
  </styleguide-layout>
</template>

<script setup>
import StyleguideLayout from "@/inertia/components/styleguide/StyleguideLayout.vue";

const onDelete = () => {
  alert("onDelete action");
};
</script>
