<template>
  <div>
    <sm-button
      :preset="preset"
      :loading="form.processing"
      @click="onDelete" />
    <sm-confirm-dialog :group="`${name}_${uid}`" />
  </div>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import uniqueId from "lodash.uniqueid";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();

const emit = defineEmits(["onDelete"]);

const uid = uniqueId();

const props = defineProps({
  route: { type: String, required: false, default: "" },
  name: { type: String, required: true, default: "delete" },
  preset: { type: String, required: false, default: "delete-icon" },
  translations: {
    type: Object, required: false, default: () => {
      return {
        header: window.trans("generic.delete_modal_title"),
        message: window.trans("generic.are_you_sure")
      };
    }
  }
});

const form = useForm({});

const onDelete = () => {
  confirm.require({
    group: `${props.name}_${uid}`,
    header: props.translations.header,
    message: props.translations.message,
    accept: () => {
      if (props.route) {
        form.delete(props.route, {
          only: ["table", "flash"]
        });
      } else {
        emit("onDelete");
      }
    }
  });
};
</script>
