<template>
  <styleguide-layout>
    <sm-anchor-point id="sm-avatar" />
    <sm-card header-title="Default usage">
      <sm-table
        :table-data="simpleTableData"
        :table-columns="simpleTableColumns" />
      <br>
      <code>
        <pre>&lt;sm-table :table-data="tableData" :table-columns="tableColumns" /&gt;</pre>
        <br>
        <pre class="text-bo-green">const tableColumns = ref([{id: "name", title: "Név"}, {id: "email", title: "E-mail"}]);
const tableData = ref([
  {
    "name": "Vedettsegi igazolvany",
    "email": "asd@asd.asd"
  },
  {
    "name": "Oltási igazolvány",
    "email": "asd@asd.asd"
  }
]);</pre></code>
    </sm-card>

    <sm-card header-title="Props">
      <styleguide-element
        title="Set the size of the table"
        prop="size"
        type="String"
        example=":size='...'"
        available-values="sm"
      >
        <sm-table
          :table-data="simpleTableData"
          :table-columns="simpleTableColumns"
          size="sm" />
      </styleguide-element>

      <styleguide-element
        title="Add border to table"
        prop="bordered"
        type="Boolean"
        example=":bordered='true'"
        default-value="false"
      >
        <sm-table
          :table-data="simpleTableData"
          :table-columns="simpleTableColumns"
          bordered />
      </styleguide-element>

      <styleguide-element
        title="Add custom class to columns"
        prop="columnClasses"
        type="Object"
        example=":column-classes='{name: 'min-w-[300px]'}'"
      >
        <sm-table
          :table-data="simpleTableData"
          :table-columns="simpleTableColumns"
          :column-classes="{name: 'min-w-[300px]'}"
          responsive />
      </styleguide-element>

      <styleguide-element
        title="Responsive table"
        prop="responsive"
        type="Boolean"
        example=":responsive='true'"
        default-value="false"
      >
        <sm-table
          :table-data="responsiveTableData"
          :table-columns="responsiveTableColumns"
          :column-classes="{message: 'min-w-[500px]'}"
          responsive />
      </styleguide-element>

      <styleguide-element
        title="Removes the border from the first line if no heading"
        prop="no-heading"
        type="Boolean"
        example=":no-heading='true'"
        default-value="false"
      >
        <sm-table
          :table-data="simpleTableData"
          :table-columns="simpleTableColumns"
          no-heading />
      </styleguide-element>

      <styleguide-element
        title="Add hover to the rows"
        prop="hover"
        type="Boolean"
        example=":hover='true'"
        default-value="false"
      >
        <sm-table
          :table-data="simpleTableData"
          :table-columns="simpleTableColumns"
          hover />
      </styleguide-element>

      <styleguide-element
        title="Add class to the specified columns"
        prop="column-classes"
        type="Object"
        example=":column-classes='{name: 'text-bo-red'}'"
      >
        <sm-table
          :table-data="simpleTableData"
          :table-columns="simpleTableColumns"
          :column-classes="{name: 'text-bo-red'}" />
      </styleguide-element>

      <styleguide-element
        title="Set the color of the table"
        prop="color"
        type="String"
        example=":color='...'"
        available-values="primary, success, danger, warning, info"
      >
        <sm-table
          :table-data="simpleTableData"
          :table-columns="simpleTableColumns"
          color="primary" />
        <br>
        <sm-table
          :table-data="simpleTableData"
          :table-columns="simpleTableColumns"
          color="primary-light" />
        <br>
        <sm-table
          :table-data="simpleTableData"
          :table-columns="simpleTableColumns"
          color="success" />
        <br>
        <sm-table
          :table-data="simpleTableData"
          :table-columns="simpleTableColumns"
          color="danger" />
        <br>
        <sm-table
          :table-data="simpleTableData"
          :table-columns="simpleTableColumns"
          color="warning" />
        <br>
        <sm-table
          :table-data="simpleTableData"
          :table-columns="simpleTableColumns"
          color="info" />
      </styleguide-element>
    </sm-card>

    <sm-card header-title="Slots">
      <styleguide-element
        title="Add slot to the column (the slotData contains all the necessary information)"
        slot-name="~column ID"
        slot-example="&lt;template #email='slotProps'&gt;{{ slotProps.data.email }}&lt;/template&gt;"
      >
        <sm-table
          :table-data="simpleTableData"
          :table-columns="simpleTableColumns">
          <template #email="slotProps">
            <a :href="`mailto:${slotProps.data.email}`">{{ slotProps.data.email }}</a>
          </template>
        </sm-table>
      </styleguide-element>
    </sm-card>

    <sm-card header-title="Default">
      <sm-datatable
        loaded
        :data="tableData">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`customers.special_cards.table.${col}`)">
            <template #body="slotProps">
              {{ slotProps.data?.[col] }}
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </styleguide-layout>
</template>

<script setup>
import StyleguideLayout from "@/inertia/components/styleguide/StyleguideLayout.vue";

const simpleTableColumns = ref([{id: "name", title: "Név"}, {id: "email", title: "E-mail"}]);

const simpleTableData = ref([
  {
    "name": "Vedettsegi igazolvany",
    "email": "asd@asd.asd"
  },
  {
    "name": "Oltási igazolvány",
    "email": "asd@asd.asd"
  }
]);

const responsiveTableColumns = ref([{id: "name", title: "Név"}, {id: "email", title: "E-mail"}, {id: "address", title: "Cím"}, {id: "message", title: "Üzenet"}]);

const responsiveTableData = ref([
  {
    "name": "Vedettsegi igazolvany",
    "email": "asd@asd.asd",
    "address": "1212. Budapest, Kiss utca 12.",
    "message": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit aperiam obcaecati, vitae iure at porro minus id, veniam voluptatibus incidunt sit. In saepe possimus mollitia repellendus consectetur, itaque accusantium rem.",
  },
  {
    "name": "Oltási igazolvány",
    "email": "asd@asd.asd",
    "address": "1212. Budapest, Kiss utca 12.",
    "message": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit aperiam obcaecati, vitae iure at porro minus id, veniam voluptatibus incidunt sit. In saepe possimus mollitia repellendus consectetur, itaque accusantium rem.",
  }
]);

const tableFields = ref([
  "name"
]);

const tableData = ref({
  "data": [
    {
      "name": "Vedettsegi igazolvany",
      "permissions": {
        "edit": true,
        "destroy": true,
        "restore": false
      },
      "routes": {
        "edit": "http://localhost:8000/manage/customers/221/special-cards/48/edit",
        "destroy": "http://localhost:8000/manage/customers/221/special-cards/48",
        "restore": "http://localhost:8000/manage/customers/221/special-cards/48/restore"
      }
    },
    {
      "name": "Oltási igazolvány",
      "permissions": {
        "edit": true,
        "destroy": true,
        "restore": false
      },
      "routes": {
        "edit": "http://localhost:8000/manage/customers/221/special-cards/47/edit",
        "destroy": "http://localhost:8000/manage/customers/221/special-cards/47",
        "restore": "http://localhost:8000/manage/customers/221/special-cards/47/restore"
      }
    }
  ],
  "links": {
    "first": "http://localhost:8000/manage/customers/221/special-cards?page=1",
    "last": "http://localhost:8000/manage/customers/221/special-cards?page=1",
    "prev": null,
    "next": null
  },
  "meta": {
    "current_page": 1,
    "from": 1,
    "last_page": 1,
    "links": [
      {
        "url": null,
        "label": "Előző",
        "active": false
      },
      {
        "url": "http://localhost:8000/manage/customers/221/special-cards?page=1",
        "label": "1",
        "active": true
      },
      {
        "url": null,
        "label": "Következő",
        "active": false
      }
    ],
    "path": "http://localhost:8000/manage/customers/221/special-cards",
    "per_page": 15,
    "to": 2,
    "total": 2
  }
});
</script>

<style lang="postcss" scoped>
  code {
    @apply inline-block;

    pre {
      @apply text-xs leading-5;
    }
  }
</style>
