<template>
  <template
    v-for="item in menu"
    :key="item.icon">
    <Link
      :href="item.url"
      class="sm-list-group-item sm-list-group-item-action"
      :class="{'active': page.url.startsWith(item.url)}"
    >
      <vue-feather
        :type="item.icon"
        class="inline w-4 h-4 mr-2 translate-y-1"
      />
      {{ item.label }}
    </Link>
    <div
      v-auto-animate>
      <div v-if="item.sub && item.sub.length !== 0 && page.url.startsWith(item.url)">
        <div
          v-for="sub in item.sub"
          :key="sub.url">
          <a
            :href="sub.url"
            class="py-1.5 pl-10 text-xs block w-full bg-white border-b transition-colors hover:bg-bo-gray-50 cursor-pointer"
            :class="{'!text-bo-blue': isHrefActive(sub.url)}">
            {{ sub.label }}
          </a>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
import { Link, usePage } from "@inertiajs/vue3";

defineProps({
  menu: { type: Array, reqiured: true, default: () => [] }
});

const page = usePage();

const isHrefActive = () => {
  return false;
  // return `${router.page.url.split("#")[1]}` === url.split("#")[1];
};

</script>
