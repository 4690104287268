<template>
  <div>
    <sm-button
      :icon="icon"
      :loading="form.processing"
      size="icon"
      @click="onSubmit" />
  </div>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";

const props = defineProps({
  route: { type: String, required: true, default: "" },
  name: { type: String, required: true, default: null },
  method: { type: String, required: true, default: "post" },
  icon: { type: String, required: true, default: null },
  data: { type: Object, required: false, default: () => ({}) }
});

const emit = defineEmits(["onSuccess"]);

const form = useForm(props.data);

const onSubmit = () => {
  form[props.method](props.route, {
    preserveScroll: true,
    onSuccess: () => {
      emit("onSuccess");
    }
  });
};
</script>
