<template>
  <div>
    <sm-card header-title="Props">
      <div class="">
        <styleguide-element
          title="Add title to the button"
          prop="title"
          type="String"
          example="title='...'"
          required
        >
          <sm-button
            title="Button name" />
        </styleguide-element>

        <styleguide-element
          title="Add hotkey string between brackets after the title"
          prop="hotkey"
          type="String"
          example="hotkey='...'"
        >
          <sm-button
            title="Button name"
            hotkey="F12" />
        </styleguide-element>

        <styleguide-element
          title="Set the color of the button"
          prop="color"
          type="String"
          available-values="primary, primary-outline, success, success-outline, info, info-outline, danger, danger-outline, modern, modern-active"
          example="color='...'"
        >
          <div class="flex flex-wrap">
            <div class="mb-2 mr-2">
              <sm-button
                title="Default (white) button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="primary"
                title="Primary button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="primary-outline"
                title="Primary outline button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="success"
                title="Success button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="success-outline"
                title="Success outline button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="info"
                title="Info button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="info-outline"
                title="Info outline button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="danger"
                title="Danger button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="danger-outline"
                title="Danger outline button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="modern"
                title="Modern button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="modern sm-btn-modern-active"
                title="Modern active button" />
            </div>
          </div>
        </styleguide-element>

        <styleguide-element
          title="Set the size of the button"
          prop="size"
          type="String"
          default-value="xs"
          available-values="xs, base, lg, input, icon"
          example="size='...'"
        >
          <div class="mb-2">
            <sm-button title="Default size button (32px)" />
          </div>
          <div class="mb-2">
            <sm-button
              size="base"
              title="Base size button (38px)" />
          </div>
          <div class="mb-2">
            <sm-button
              size="lg"
              title="Lg size button (40px)" />
          </div>
          <div class="mb-2">
            <sm-button
              size="input"
              title="Input size button (38px)" />
          </div>
          <div class="mb-2">
            <sm-button
              size="icon"
              icon="check" />
          </div>
        </styleguide-element>

        <styleguide-element
          title="Add feather icon before / after to the button"
          prop="icon"
          type="String"
          example="icon='...'"
        >
          <sm-button
            icon="check"
            title="Button with icon" />
        </styleguide-element>

        <styleguide-element
          title="Set the position of the the icon "
          prop="icon-position"
          type="String"
          available-values="left, right"
          example="icon-position='...'"
        >
          <div class="mb-2">
            <sm-button
              icon="check"
              icon-position="left"
              title="Button with icon on the left" />
          </div>
          <div class="mb-2">
            <sm-button
              icon="check"
              icon-position="right"
              title="Button with icon on the right" />
          </div>
        </styleguide-element>

        <styleguide-element
          title="Add loading indicator (with disabled state) to the button"
          prop="loading"
          type="Boolean"
          default-value="false"
          example=":loading='true'"
        >
          <sm-button
            title="Loader button (click to toggle)"
            :loading="loading"
            @click="toggleLoading" />
        </styleguide-element>

        <styleguide-element
          title="Add disabled state to the button"
          prop="disabled"
          type="Boolean"
          default-value="false"
          example=":disabled='true'"
        >
          <sm-button
            disabled
            title="Button with disabled state" />
        </styleguide-element>

        <hr>

        <styleguide-element
          title="Change the type of the html button"
          prop="type"
          type="String"
          example="type='...'"
        >
          <sm-button
            type="submit"
            title="This is a submit button" />
        </styleguide-element>

        <styleguide-element
          title="Create a simple link (&lt;a /&gt; tag) with button style"
          prop="href"
          type="String"
          example="href='...'"
        >
          <sm-button
            href="#"
            title="Simple link" />
        </styleguide-element>

        <styleguide-element
          title="Generate link as any html element"
          prop="as"
          type="String"
          example="as='...'"
        >
          <sm-button
            href="#"
            as="span"
            title="Button link generated as a span" />
        </styleguide-element>

        <hr>

        <styleguide-element
          title="Define the default style and text of the button as a preset"
          prop="preset"
          type="String"
          available-values="dd, save, delete, delete-icon, edit, edit-icon, back"
          example="preset='...'"
        >
          <div class="flex flex-wrap space-x-2">
            <div class="mb-2">
              <sm-button preset="add" />
            </div>
            <div class="mb-2">
              <sm-button preset="save" />
            </div>
            <div class="flex mb-2">
              <sm-button
                preset="delete"
                class="mr-2" />
              <sm-button preset="delete-icon" />
            </div>
            <div class="flex mb-2">
              <sm-button
                preset="edit"
                class="mr-2" />
              <sm-button preset="edit-icon" />
            </div>
            <div class="mb-2">
              <sm-button preset="back" />
            </div>
          </div>
        </styleguide-element>
      </div>
    </sm-card>
  </div>
</template>

<script setup>
const loading = ref(false);
const toggleLoading = () => {
  loading.value = !loading.value;
  setTimeout(() => {
    loading.value = !loading.value;
  }, 1500);
};
</script>
