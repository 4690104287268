<template>
  <div>
    <sm-card header-title="Default usage">
      <sm-date-picker
        label="Date picker" />
      <code class="block !-mt-4"><pre class="text-xs">&lt;date-picker label="..."  /&gt;</pre></code>
      <hr>
      <sm-date-range-picker
        label="Date range picker" />
      <code class="block !-mt-4"><pre class="text-xs">&lt;date-range-picker label="..."  /&gt;</pre></code>
      <hr>
      <sm-time-picker
        label="Time picker" />
      <code class="block !-mt-4"><pre class="text-xs">&lt;time-picker label="..."  /&gt;</pre></code>
    </sm-card>

    <sm-card header-title="Value bindings">
      <sm-date-picker
        v-model="datePicker"
        label="Date picker" />
      <code class="block !-mt-4"><pre class="text-xs">v-model="..."</pre></code><br>
      <sm-date-picker
        :model-value="datePickerValue"
        label="Date picker" />
      <code class="block !-mt-4"><pre class="text-xs">:model-value="..."</pre></code>
      <hr>
      <sm-time-picker
        v-model="timePicker"
        label="Time picker" />
      <code class="block !-mt-4"><pre class="text-xs">v-model="..."</pre></code><br>
      <sm-time-picker
        :model-value="timePickerValue"
        label="Date picker" />
      <code class="block !-mt-4"><pre class="text-xs">:model-value="..."</pre></code>
      <hr>
      <sm-date-range-picker
        v-model="dateRangePickerValue"
        label="Date range picker" />
      <code class="block !-mt-4"><pre class="text-xs">v-model="..."</pre></code><br>
      <sm-date-range-picker
        :model-value="dateRangePickerValue"
        label="Date range picker" />
      <code class="block !-mt-4"><pre class="text-xs">:model-value="..."</pre></code>
    </sm-card>

    <sm-card header-title="Texts and helps">
      <sm-date-picker
        label="Label for date picker" />
      <code class="block !-mt-4"><pre class="text-xs">label="..."</pre></code><br>
      <sm-date-range-picker
        label="Label for date range picker" />
      <code class="block !-mt-4"><pre class="text-xs">label="..."</pre></code><br>
      <sm-time-picker
        label="Label for time picker" />
      <code class="block !-mt-4"><pre class="text-xs">label="..."</pre></code>
      <hr>
      <sm-date-picker
        placeholder="Placeholder for date picker" />
      <code class="block !-mt-4"><pre class="text-xs">placeholder="..."</pre></code><br>
      <sm-date-range-picker
        placeholder="Placeholder for date range picker" />
      <code class="block !-mt-4"><pre class="text-xs">placeholder="..."</pre></code><br>
      <sm-time-picker
        placeholder="Placeholder for time picker" />
      <code class="block !-mt-4"><pre class="text-xs">placeholder="..."</pre></code>
      <hr>
      <sm-date-picker
        help="Help text for date picker" />
      <code class="block !-mt-4"><pre class="text-xs">help="..."</pre></code><br>
      <sm-date-range-picker
        help="Help text for date range picker" />
      <code class="block !-mt-4"><pre class="text-xs">help="..."</pre></code><br>
      <sm-time-picker
        help="Help text for time picker" />
      <code class="block !-mt-4"><pre class="text-xs">help="..."</pre></code>
    </sm-card>
  </div>
</template>

<script setup>
const timePicker = ref("11:00");
const datePickerValue = ref("2021-10-01");
const timePickerValue = ref("14:26");
const datePicker = ref("2023-09-01");
const dateRangePickerValue = ref({start: "2024-07-10", end: "2024-07-20"});
</script>
