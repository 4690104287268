<template>
  <styleguide-layout>
    <sm-card header-title="Default usage">
      <sm-button
        title="Click me"
        @click="onConfirmBase" />
      <sm-confirm-dialog
        title="Confirm"
        group="confirmGroup" />
      <br>
      <code>
        <pre>&lt;sm-button title="Click me" @click="onConfirm" /&gt;</pre>
        <pre class="mb-4 ">&lt;sm-confirm-dialog title="Confirm title" group="confirmGroup" /&gt;</pre>
        <pre class="text-bo-green">const confirm = useConfirm();
const onConfirm = () => {
  confirm.require({
    group: "confirmGroup",
    header: "Some header",
    accept: () => acceptAction;
  });
};</pre></code>
    </sm-card>

    <sm-card header-title="The confirm object">
      <sm-alert color="info">
        <div>
          These props are part of the <strong>confirm.require()</strong> functions first param.<br>
          Fore more information: <a
            class="underline"
            href="https://v3.primevue.org/confirmdialog/"
            target="_blank">https://v3.primevue.org/confirmdialog/</a>
        </div>
      </sm-alert>
      <div class="grid grid-cols-2 gap-4">
        <styleguide-element
          title="Set the group of the confirm modal"
          prop="group"
          type="String"
          example="group='...'"
          required
        />
        <styleguide-element
          title="Add text to the header"
          prop="header"
          type="String"
          example="header='...'"
        />
        <styleguide-element
          title="Add text ot the body"
          prop="message"
          type="String"
          example="message='...'"
        />
        <styleguide-element
          title="Add some function after the user accepted the dialog"
          prop="accept"
          type="Function"
          example="accept='onAccept'"
        />
        <styleguide-element
          title="Add some function after the user declined the dialog"
          prop="reject"
          type="Function"
          example="reject='onReject'"
        />
      </div>
    </sm-card>

    <sm-card header-title="Props">
      <styleguide-element
        title="Set the group of the confirm modal"
        prop="group"
        type="String"
        example="group='...'"
        required
      >
        <sm-button
          title="Click me"
          @click="onConfirm(1)" />
        <sm-confirm-dialog
          title="Confirm"
          group="confirmGroup_1" />
      </styleguide-element>

      <styleguide-element
        title="Show / Hide the accept button"
        prop="hasAcceptButton"
        type="Boolean"
        default-value="true"
        example=":has-accept-button='false'"
      >
        <sm-button
          title="Click me"
          @click="onConfirm(2)" />
        <sm-confirm-dialog
          title="Confirm"
          :has-accept-button="false"
          group="confirmGroup_2" />
      </styleguide-element>

      <styleguide-element
        title="Add custom label to the accept button"
        prop="acceptLabel"
        type="String"
        :default-value="$t('generic.yes')"
        example="accept-label='...'"
      >
        <sm-button
          title="Click me"
          @click="onConfirm(3)" />
        <sm-confirm-dialog
          title="Confirm"
          accept-label="Okey Dokey"
          :has-accept-button="false"
          group="confirmGroup_3" />
      </styleguide-element>

      <styleguide-element
        title="Show / Hide the fake accept button (emits a new 'onFakeAccept' event for custom purposes)"
        prop="hasFakeAcceptButton"
        type="Boolean"
        default-value="false"
        example=":has-fake-accept-button='true' @onFakeAccept='onFakeAccept'"
      >
        <sm-button
          title="Click me"
          @click="onConfirm(4)" />
        <sm-confirm-dialog
          title="Confirm"
          :has-fake-accept-button="true"
          group="confirmGroup_4" />
      </styleguide-element>

      <styleguide-element
        title="Add custom label to the fake accept button"
        prop="fakeAcceptLabel"
        type="String"
        :default-value="$t('generic.yes')"
        example="fake-accept-label='...'"
      >
        <sm-button
          title="Click me"
          @click="onConfirm(5)" />
        <sm-confirm-dialog
          title="Confirm"
          fake-accept-label="Okey Dokey"
          has-fake-accept-button
          group="confirmGroup_5" />
      </styleguide-element>

      <styleguide-element
        title="Show / Hide the cancel button"
        prop="hasCancelButton"
        type="Boolean"
        default-value="true"
        example=":has-cancel-button='false'"
      >
        <sm-button
          title="Click me"
          @click="onConfirm(6)" />
        <sm-confirm-dialog
          title="Confirm"
          :has-cancel-button="true"
          group="confirmGroup_6" />
      </styleguide-element>

      <styleguide-element
        title="Add custom label to the cancel button"
        prop="cancelLabel"
        type="String"
        :default-value="$t('generic.yes')"
        example="cancel-label='...'"
      >
        <sm-button
          title="Click me"
          @click="onConfirm(7)" />
        <sm-confirm-dialog
          title="Confirm"
          cancel-label="Nope"
          group="confirmGroup_7" />
      </styleguide-element>
    </sm-card>
  </styleguide-layout>
</template>

<script setup>
import StyleguideLayout from "@/inertia/components/styleguide/StyleguideLayout.vue";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();

const onConfirmBase = () => {
  confirm.require({
    group: "confirmGroup",
    header: "Some header",
    accept: () => { console.log("Accepted"); },
    reject: () => { console.log("Rejected"); }
  });
};

const onConfirm = (i) => {
  confirm.require({
    group: `confirmGroup_${i}`,
    header: "Some header",
    accept: () => { console.log("Accepted"); },
    reject: () => { console.log("Rejected"); }
  });
};

</script>

<style lang="postcss" scoped>
  code {
    @apply inline-block;

    pre {
      @apply text-xs leading-5;
    }
  }
</style>
