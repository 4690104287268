<template>
  <styleguide-layout>
    <sm-card header-title="Default usage">
      <sm-card />
      <br>
      <code class="block !-mt-4"><pre class="text-xs">&lt;sm-card /&gt;</pre></code>
    </sm-card>

    <sm-card header-title="Props">
      <div class="">
        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add the title to the card<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> headerTitle<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">header-title="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-card header-title="Header title prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add the info to the cards title (only works when title is available)<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> headerInfo<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">header-title="..." header-info="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-card
              header-info="Header info prop"
              header-title="Header title and ->" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Remove the padding from the card<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> noPadding<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:no-padding="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-card no-padding>
              No padding card
            </sm-card>
          </div>
        </div>
        <hr>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add save button to the bottom of the card. The button emits the onSubmit event. This needs to be specified to work.<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> form<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Object<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:form="form"</pre></code><br>
              <strong class="inline-block pl-2 w-36">Emit:</strong><code class="inline-block pl-2"><pre class="text-xs text-bo-yellow-600">@onSubmit="onSubmit"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-card :form="form" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add back button to the bottom of the card.<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> back<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String (URL)<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">back="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-card
              :form="form"
              back="/inertia/styleguide" />
          </div>
        </div>
        <hr>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add extra classes to the header (only works when title is available)<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> headerClasses<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">header-classes="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-card
              header-title="Header"
              header-classes="bg-bo-blue" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add extra classes to the body<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> bodyClasses<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">body-classes="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-card
              body-classes="bg-bo-blue" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add extra classes to the footer (only works when footer slot is available)<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> footerClasses<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">footer-classes="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-card
              footer-classes="bg-bo-blue">
              <template #footer>
                Footer
              </template>
            </sm-card>
          </div>
        </div>
        <hr>
      </div>
    </sm-card>

    <sm-card header-title="Slots">
      <div class="">
        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add slot to the body (default slot)<br>
              <strong class="inline-block pl-2 w-36">Slot name:</strong> #default<br>
              <code class="inline-block pl-2"><pre class="text-xs text-bo-green">&lt;sm-card&gt;Body&lt;/sm-card&gt;</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-card>
              Body slot
            </sm-card>
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add slot to the header<br>
              <strong class="inline-block pl-2 w-36">Slot name:</strong> #header<br>
              <code class="inline-block pl-2"><pre class="text-xs text-bo-green">&lt;template #header&gt;Header&lt;/template&gt;</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-card>
              <template #header>
                Header slot
              </template>
            </sm-card>
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add slot after the header<br>
              <strong class="inline-block pl-2 w-36">Slot name:</strong> #headerActions<br>
              <code class="inline-block pl-2"><pre class="text-xs text-bo-green">&lt;template #headerActions&gt;Header Actions&lt;/template&gt;</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-card header-title="Header">
              <template #headerActions>
                Header actions
              </template>
            </sm-card>
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add slot to the footer<br>
              <strong class="inline-block pl-2 w-36">Slot name:</strong> #footer<br>
              <code class="inline-block pl-2"><pre class="text-xs text-bo-green">&lt;template #footer&gt;Footer&lt;/template&gt;</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-card>
              <template #footer>
                Footer slot
              </template>
            </sm-card>
          </div>
        </div>
        <hr>
      </div>
    </sm-card>
  </styleguide-layout>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import StyleguideLayout from "../../../components/styleguide/StyleguideLayout.vue";

const form = useForm({});
</script>
