<template>
  <div>
    <In-Head title="Styleguide (vue)" />

    <sub-header title="Styleguide (vue)" />

    <div class="mb-6 sm-row">
      <div class="sticky top-0 w-full mb-4 sm-col lg:w-3/12 lg:mb-0">
        <div class="sm-list-group sm-list-group-bordered">
          <Link
            href="/inertia/styleguide"
            class="sm-list-group-item sm-list-group-item-action"
          >
            <span class="font-jost-bold">Home</span>
          </Link>
          <Link
            :href="formsMenu[0].url"
            class="w-full text-left sm-list-group-item sm-list-group-item-action">
            <span class="font-jost-bold">Forms</span>
          </Link>
          <template v-if="formsMenu.map(data => data.url.split('/')[3]).includes(page.url.split('/')[3])">
            <StyleguideLayoutMenuItem :menu="formsMenu" />
          </template>
          <Link
            :href="elementsMenu[0].url"
            class="w-full text-left sm-list-group-item sm-list-group-item-action">
            <span class="font-jost-bold">Elements</span>
          </Link>
          <template v-if="elementsMenu.map(data => data.url.split('/')[3]).includes(page.url.split('/')[3])">
            <StyleguideLayoutMenuItem :menu="elementsMenu" />
          </template>
        </div>
      </div>
      <div class="w-full sm-col lg:w-9/12">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
import { Link, usePage } from "@inertiajs/vue3";
import StyleguideLayoutMenuItem from "./Styleguide/StyleguideLayoutMenuItem.vue";

const page = usePage();

const elementsMenu = ref([
  {
    url: "/inertia/styleguide/cards",
    icon: "book",
    label: "Cards",
    sub: [
      {
        url: "#defaults",
        label: "Default",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
    ]
  },
  {
    url: "/inertia/styleguide/modals",
    icon: "cloud",
    label: "Modals",
    sub: [
      {
        url: "#defaults",
        label: "Default",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
      {
        url: "#emits",
        label: "Emits",
      }
    ]
  },
  {
    url: "/inertia/styleguide/alert-dialog",
    icon: "alert-triangle",
    label: "Alert dialog",
    sub: [
      {
        url: "#defaults",
        label: "Default",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#emits",
        label: "Emits",
      }
    ]
  },
  {
    url: "/inertia/styleguide/confirm-dialog",
    icon: "alert-circle",
    label: "Confirm dialog",
    sub: [
      {
        url: "#defaults",
        label: "Default",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#emits",
        label: "Emits",
      }
    ]
  },
  {
    url: "/inertia/styleguide/tables",
    icon: "table",
    label: "Tables",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
    ]
  },
  {
    url: "/inertia/styleguide/unique-elements",
    icon: "star",
    label: "Unique elements",
    sub: [
      {
        url: "#sm-avatar",
        label: "SmAvatar",
      },
      {
        url: "#sm-boolean-flag",
        label: "SmBooleanFlag",
      },
      {
        url: "#sm-anchor-point",
        label: "SmAnchorPoint",
      },
    ]
  }
]);

const formsMenu = ref([
  {
    url: "/inertia/styleguide/form",
    icon: "align-justify",
    label: "Form",
  },
  {
    url: "/inertia/styleguide/buttons",
    icon: "inbox",
    label: "Buttons",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
      {
        url: "#emits",
        label: "Emits",
      },
    ]
  },
  {
    url: "/inertia/styleguide/toggle-button",
    icon: "airplay",
    label: "Toggle Button",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
      {
        url: "#emits",
        label: "Emits",
      },
    ]
  },
  {
    url: "/inertia/styleguide/input-wrapper",
    icon: "plus",
    label: "Input wrapper",
    sub: [
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      }
    ]
  },
  {
    url: "/inertia/styleguide/labels",
    icon: "minus",
    label: "Labels",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      }
    ]
  },
  {
    url: "/inertia/styleguide/inputs",
    icon: "square",
    label: "Inputs",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
      {
        url: "#emits",
        label: "Emits",
      },
    ]
  },
  {
    url: "/inertia/styleguide/selects",
    icon: "menu",
    label: "Selects",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
      {
        url: "#async-defaults",
        label: "Async Defaults",
      },
      {
        url: "#async-props",
        label: "Async Props",
      },
      {
        url: "#async-slots",
        label: "Async Slots",
      }
    ]
  },
  {
    url: "/inertia/styleguide/pickers",
    icon: "server",
    label: "Pickers",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
      {
        url: "#emits",
        label: "Emits",
      },
    ]
  },
  {
    url: "/inertia/styleguide/checkboxes",
    icon: "check-square",
    label: "Checkboxes",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
      {
        url: "#emits",
        label: "Emits",
      },
    ]
  },
  {
    url: "/inertia/styleguide/radios",
    icon: "check-circle",
    label: "Radios",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
      {
        url: "#emits",
        label: "Emits",
      },
    ]
  },
  {
    url: "/inertia/styleguide/form-button",
    label: "Form button & Delete button",
    icon: "send",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
      {
        url: "#emits",
        label: "Emits",
      },
    ]
  }
]);
</script>
