import { format, isValid } from "date-fns";

export const globalInputProps = {
  name: { type: String, required: false, default: "" },
  id: { type: String, required: false, default: "" },
  label: { type: String, required: false, default: "" },
  placeholder: { type: [String, Boolean], required: false, default: "" },
  tooltip: { type: String, required: false, default: "" },
  help: { type: String, required: false, default: "" },
  error: { type: [Boolean, String], required: false, default: false },
  errors: { type: Array, required: false, default: () => [] },
  inline: { type: Boolean, required: false, default: false },
  half: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false },
  required: { type: Boolean, required: false, default: false },
  optional: { type: Boolean, required: false, default: false },
  inputClasses: { type: String, required: false, default: "" },
  inputStyle: { type: String, required: false, default: "" },
  prependStyle: { type: String, required: false, default: null },
  appendStyle: { type: String, required: false, default: null },
};

export function usePriceFormat(price) {
  return new Intl.NumberFormat("hu-HU", {
    style: "currency",
    currency: "HUF",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price);
}

export function useNumberFormat(number) {
  return new Intl.NumberFormat("hu-HU").format(number);
}

export function useDateFormat(date, type) {
  const parsedDate = new Date(date);

  if (!date) {
    return type === "form" ? null : "-";
  }

  if (!isValid(parsedDate)) {
    return date;
  }

  if (type) {
    if (type === "long") {
      return format(parsedDate, "yyyy. MM. dd. HH:mm:ss");
    } else if (type === "seconds") {
      return format(parsedDate, "HH:mm:ss:SS");
    } else if (type === "form") {
      return format(parsedDate, "yyyy-MM-dd");
    } else if (type === "date_time") {
      return format(parsedDate, "yyyy. MM. dd. HH:mm");
    } else if (type === "time") {
      return format(parsedDate, "HH:mm");
    } else {
      return format(parsedDate, type);
    }
  } else {
    return format(parsedDate, "yyyy. MM. dd.");
  }
}

export function useFormatSelect(selectOptions) {
  return Object.entries(selectOptions).map(entry => {
    return {
      id: entry[0],
      label: entry[1]
    };
  });
}

export function useFormatArrayToTrueFalseObjects(radioArray, type, translation) {
  return [
    {
      id: radioArray[0],
      value: "true",
      text: translation ? translation[0] : type === "enabled_disabled" ? window.trans("generic.enabled") : window.trans("generic.yes")
    }, {
      id: radioArray[1],
      value: "false",
      text: translation ? translation[1] : type === "enabled_disabled" ? window.trans("generic.disabled") : window.trans("generic.no")
    }
  ];
}
export function useFormatToSnakeCase(str) {
  return str && str.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(s => s.toLowerCase())
    .join("_");
}
